import * as type from './type';
import { differenceInDays, format } from 'date-fns';


/**
 * 引数に入れた数字を全角にする関数
 * @param num 回数や日数
 * @returns 全角にした回数や日数
 */
export const toFullWidth = (num: number) => {
  const str = String(num)
  return str.replace(/[0-9]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
  });
}

/**
 * 引数に入れた全角数字を半角にする関数
 * @param str フォームが全角のときの数字 
 * @returns 半角にした数字
 */
export const toHalfwidth = (str: string) => str.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))

/**
 * 整数かどうか判断する関数。with ChatGPT3.5
 * @param arg 
 * @returns 
 */
export const validateNumber = (arg: string) => {
  // 全角数字を半角数字に変換する
  const normalizedArg = toHalfwidth(arg);

  // 正規表現を使用して整数かどうかを判定する
  const integerRegex = /^[0-9]+$/;
  return integerRegex.test(normalizedArg);
}

/**
 * 現在との日付の差分を取る関数 with ChatGPT3.5
 * @param startDate 開始日 ex:2023-05-01
 * @returns {number} 差分
 */
export const getDayDiff = (startDate: string) => {
  const today = new Date();
  const diff = differenceInDays(today, new Date(startDate));
  return diff;
}

/**
 * 現在の日付を取得する関数
 * @returns yyyy-mm-dd
 */
export const getCurrentDate = () => {
  const currentDate = new Date();
  const formattedDate = format(currentDate, 'yyyy-MM-dd');
  return formattedDate;
}