import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/BorderColorRounded';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { Amplify, API, graphqlOperation } from "aws-amplify";
import awsconfig from "./aws-exports";
import * as type from './type';
import { MAIN_COLOR, SUB_COLOR, ACCENT_COLOR, DISABLE_COLOR } from './Const';
import { toFullWidth, toHalfwidth, getDayDiff } from './commonLogics';
import { updateItemCount, resetItem, updateItemLabelColor, deleteItem } from './dbLogics';
import ColorPicker from './ColorPicker';
import './App.css';

Amplify.configure(awsconfig);

const ItemDisplay = (props: type.itemChildProps) => {
  const [isColorEditState, setIsColorEditState] = useState(false)

  const dayCount = getDayDiff(props.item.date) //現在日時とリセット日の差分を取る

  /**
   * 使用回数を増やす関数
   */
  const onClickCountUp = async () => {
    const updatedItem = await updateItemCount(props.item.id, props.item.count + 1)
    // 更新がうまくいけば更新内容でstateを変える
    if (updatedItem) {
      props.setItemState(updatedItem)
    }
  }

  /**
   * リセットする関数
  */
  const onClickReset = async () => {
    const updatedItem = await resetItem(props.item.id)
    // 更新がうまくいけば更新内容でstateを変える
    if (updatedItem) {
      props.setItemState(updatedItem)
    }
  }

  /**
   * 削除する関数
  */
  const onClickDelete = async () => {
    if (window.confirm("このアイテムを削除しますか？")) {
      const deletedItem = await deleteItem(props.item.id)
      // 更新がうまくいけば更新内容でstateを変える
      if (deletedItem) {
        props.setItemState(undefined)
      }
    }
  }

  /**
   * Editモードに変更する関数
   */
  const changeToEditMode = () => {
    props.setIsEditState((current) => !current)
  }


  // style
  // 条件で変化するもの
  const countColor = props.item.count < props.item.countLimit || props.item.countLimit === 0 ? "black" : "red" // Limit以上のとき色を変える
  const dayCountColor = dayCount < props.item.dayCountLimit || props.item.dayCountLimit === 0 ? "black" : "red" // Limit以上のとき色を変える
  // 共通
  const cardStyle: React.CSSProperties = { display: "flex", flexDirection: "column", margin: "20px", boxShadow: "0px 5px 10px rgba(0,0,0,0.2)", }
  // データ部分
  const contentStyle: React.CSSProperties = { display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 5px 10px 15px", backgroundColor: SUB_COLOR, }
  const valueRowStyle: React.CSSProperties = { display: "flex", gap: "10px", alignItems: "center", }
  const rowStyle: React.CSSProperties = { display: "flex", gap: "5px", alignItems: "end", }
  const valueStyle: React.CSSProperties = { fontSize: "larger", }
  const limitStyle: React.CSSProperties = { fontSize: "small", whiteSpace: "nowrap", }
  // ボタン部分
  const btnContentStyle: React.CSSProperties = { display: "flex", justifyContent: "space-between", padding: "10px", backgroundColor: "white" }
  const labelStyle: React.CSSProperties = { width: "60px", backgroundColor: props.item.labelColor, }
  const btnBlockStyle: React.CSSProperties = { display: "flex", gap: "10px", }
  const mainBtnStyle: React.CSSProperties = { borderRadius: "10px", backgroundColor: ACCENT_COLOR, color: "white", fontWeight: "bold" }


  return (
    <div className='card' style={{ ...cardStyle }}>
      <div className='content' style={{ ...contentStyle }}>
        <div className='title' style={{}}>{props.item.title}</div>
        <div className='value' style={{ ...valueRowStyle }}>
          <div className='count' style={{ ...rowStyle }}>
            <div style={{ ...valueStyle, color: countColor }}>{toFullWidth(props.item.count)}</div>
            <div style={{ ...limitStyle }}>回 /{props.item.countLimit}</div>
          </div>
          <div className='days' style={{ ...rowStyle }}>
            <div style={{ ...valueStyle, color: dayCountColor }}>{toFullWidth(dayCount)}</div>
            <div style={{ ...limitStyle }}>日 /{props.item.dayCountLimit}</div>
          </div>
          <IconButton style={{}} onClick={changeToEditMode}>
            <EditIcon />
          </IconButton>
        </div>
      </div>
      <div className='btnContent' style={{ ...btnContentStyle }}>
        <div className='label' style={{ ...labelStyle }} onClick={() => setIsColorEditState((current) => !current)}></div>
        <div className='btnBlock' style={{ ...btnBlockStyle }}>
          <IconButton style={{ padding: "0px" }} onClick={onClickDelete}>
            <DeleteIcon style={{ fontSize: '30px' }} />
          </IconButton>
          <Button variant="contained" onClick={onClickReset} style={{ ...mainBtnStyle }}>リセット</Button>
          <Button variant="contained" onClick={onClickCountUp} style={{ ...mainBtnStyle }}>つかった</Button>
        </div>
      </div>
      {
        isColorEditState &&
        <ColorPicker item={props.item} setItemState={props.setItemState} setIsColorEditState={setIsColorEditState} />
      }
    </div>
  );
}

export default ItemDisplay;
