import React, { useEffect, useState } from 'react'
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { styled } from '@mui/material/styles';
import awsconfig from "./aws-exports";
import * as type from './type';
import { MAIN_COLOR, SUB_COLOR, ACCENT_COLOR, DISABLE_COLOR } from './Const';
import ItemDisplay from './ItemDisplay';
import ItemEdit from './ItemEdit';
import './App.css';
import { LazyItem } from './models';

Amplify.configure(awsconfig);

const Item = (props: type.itemProps) => {
  const [itemState, setItemState] = useState<LazyItem | undefined>(props.item)
  const [isEditState, setIsEditState] = useState(false)

  // item消去時には何も表示させない
  if (itemState === undefined) {
    return (
      <div></div>
    )
  }

  // 次に渡すProps
  const itemChildProps: type.itemChildProps = {
    item: itemState,
    setItemState: setItemState,
    setIsEditState: setIsEditState,
  }

  return (
    <div>
      {
        isEditState ?
          <ItemEdit {...itemChildProps} /> // 編集時
          :
          <ItemDisplay {...itemChildProps} />// 非編集時
      }
    </div>
  );
}

export default Item;
