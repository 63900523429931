import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { isMobile } from "react-device-detect";
import { Amplify, API, graphqlOperation } from "aws-amplify";
import awsconfig from "./aws-exports";
import { LazyItem } from './models';
import * as type from './type';
import Item from './Item'
import { MAIN_COLOR, SUB_COLOR, ACCENT_COLOR, MOCK, userIdMOCK } from './Const';
import { getItemsByUserId, createItem, createItemMOCK, deleteAllItemMOCK, clearItem } from './dbLogics';
import NewItem from './NewItem';
import icon from './icon.svg';
import './App.css';

Amplify.configure(awsconfig);

function App() {
  const [itemAry, setItemAry] = useState<LazyItem[]>([])
  const [isCreateItemState, setIsCreateItemState] = useState(false)
  // const [itemAry, setItemAry] = useState(MOCK)

  useEffect(() => { getItemsEffect() }, [])

  /**
   * ページ読み込み時にItemをDBから取る関数
   */
  const getItemsEffect = async () => {
    // await createItemMOCK() //MOCKをDBに保存
    // await deleteAllItemMOCK() //MOCKをDBからすべて削除
    // await clearItem() //localからすべて削除

    const items = await getItemsByUserId(userIdMOCK)
    console.dir(items)
    setItemAry(items)
  }

  // style
  const appStyle: React.CSSProperties = { display: "flex", flexDirection: "column", alignItems: "center", minHeight: "100vh", padding: "70px 0px", backgroundColor: MAIN_COLOR, fontWeight: "bold", wordBreak: "break-word", overflowWrap: "break-word", }
  const pageTitleStyle: React.CSSProperties = { display: "flex", justifyContent: "center", gap: "10px", position: "fixed", top: "0px", zIndex: "9999", width: "100%", padding: "15px 5px", textAlign: "center", fontSize: "medium", backgroundColor: MAIN_COLOR, color: "white", boxShadow: "0px 5px 10px rgba(0,0,0,0.2)" }
  const containerStyle: React.CSSProperties = { width: "100%", maxWidth: "800px", minWidth: "350px" }
  const createItemBtnStyle: React.CSSProperties = { position: "fixed", bottom: "100px", right: "30px", zIndex: "9998", backgroundColor: ACCENT_COLOR, color: "white", fontSize: "35px", padding: "0px", borderRadius: "50%", boxShadow: "0px 5px 10px rgba(0,0,0,0.2)", }

  return (
    <div className="App" style={{ ...appStyle }}>
      <div className='pageTitle' style={{ ...pageTitleStyle }}>
        <img src={icon} width="70" height="30" alt="アイコン" />
        <div style={{ display: "flex", alignItems: "center", }}>
          マダイケる.com
        </div>
      </div>
      <div className='container' style={{ ...containerStyle }}>
        {
          isCreateItemState &&
          <NewItem setIsCreateItemState={setIsCreateItemState} setItemAry={setItemAry} />
        }
        {
          itemAry.map(item => <Item key={item.id} item={item} setAppState={() => setItemAry}></Item>)
        }
        <Button variant="contained" onClick={() => setIsCreateItemState((current) => !current)} style={{ ...createItemBtnStyle }}>+</Button>
      </div>
    </div>
  );
}

export default App;
