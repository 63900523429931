import React, { useEffect, useState } from 'react'
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import awsconfig from "./aws-exports";
import * as type from './type';
import { MAIN_COLOR, SUB_COLOR, ACCENT_COLOR, DISABLE_COLOR } from './Const';
import { toHalfwidth, validateNumber, } from './commonLogics';
import { updateItemByEditForm } from './dbLogics';
import ColorPicker from './ColorPicker';
import './App.css';

Amplify.configure(awsconfig);

const ItemEdit = (props: type.itemChildProps) => {
  const [formStateObj, setFormStateObj] = useState<type.itemEditForm>({ title: props.item.title, count: String(props.item.count), countLimit: String(props.item.countLimit), date: String(props.item.date), dayCountLimit: String(props.item.dayCountLimit), labelColor: props.item.labelColor })
  const [isFormErrorStateObj, setIsFormErrorStateObj] = useState({ title: false, count: false, countLimit: false, date: false, dayCountLimit: false })
  const [disableApplyBtnState, setDisableApplyBtnState] = useState(false)
  const [inputDateFocusState, setInputDateFocusState] = useState(false)
  const [isColorEditState, setIsColorEditState] = useState(false)


  /**
   * Inputフォームの入力をStateに反映させる関数
   * @param stateName inputのフォームがどれなのかStateを入力
   * @param input フォームの入力内容
   */
  const onChangeInput = (stateName: type.itemFormStateName, input: string) => {
    // 入力された内容を保管
    const formObj = { ...formStateObj, [stateName]: input }

    // フォームエラーを確認
    let isFormError
    if (stateName === "title" || stateName === "date") {
      isFormError = input === "" ? true : false // 空文字ならエラー
    } else {
      const isNaturalNum = validateNumber(input)
      isFormError = !isNaturalNum ? true : false // 自然数じゃないならエラー
    }
    const isFormErrorObj = { ...isFormErrorStateObj, [stateName]: isFormError }

    // フォームエラー存在時は編集確定ボタンを無効化する
    const isFormErrorValueAry = Object.values(isFormErrorObj)
    const disableApplyBtn = isFormErrorValueAry.includes(true) ? true : false

    setFormStateObj(formObj)
    setIsFormErrorStateObj(isFormErrorObj)
    setDisableApplyBtnState(disableApplyBtn)
  }

  /**
 * 編集キャンセルボタン。フォーム内容を反映させない
 */
  const clickCancel = () => { props.setIsEditState(false) }

  /**
   * 編集確定ボタン。フォーム内容を反映させる
   */
  const clickApply = async () => {
    // 編集確定ボタンが押される時点でバリデーションされて数字なのは保障されている
    // 全角数字を半角数字にする
    const formObj: type.itemEditForm = {
      title: formStateObj.title,
      count: toHalfwidth(formStateObj.count),
      countLimit: toHalfwidth(formStateObj.countLimit),
      date: formStateObj.date,
      dayCountLimit: toHalfwidth(formStateObj.dayCountLimit),
      labelColor: formStateObj.labelColor,
    }

    const updatedItem = await updateItemByEditForm(props.item.id, formObj)
    // 更新がうまくいけば更新内容でstateを変える
    if (updatedItem) {
      props.setItemState(updatedItem)
      props.setIsEditState((current) => !current)
    }
  }

  // style
  // 共通
  const cardStyle: React.CSSProperties = { display: "flex", flexDirection: "column", margin: "20px", boxShadow: "0px 5px 10px rgba(0,0,0,0.2)", }
  // データ部分
  const contentEditStyle: React.CSSProperties = { display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "15px", padding: "15px 15px", backgroundColor: SUB_COLOR, }
  const rowEditStyle: React.CSSProperties = { display: "flex", gap: "10px", alignItems: "center", width: "100%" }
  const titleEditStyle: React.CSSProperties = {}
  const TextFieldPropsFn = (stateName: type.itemFormStateName): TextFieldProps => {
    return {
      variant: "standard",
      multiline: true,
      fullWidth: true,
      inputProps: { style: { fontSize: "large", padding: "0px 5px" } },
      value: formStateObj[stateName],
      error: isFormErrorStateObj[stateName],
      onChange: (e: any) => onChangeInput(stateName, e.target.value),
    }
  }
  const valueEditStyle: React.CSSProperties = { whiteSpace: "nowrap", }
  // dateInput
  // フォーカスとエラーによって下線の色や太さを変える
  const dateBorderBottomColor = isFormErrorStateObj.date ? "#d32f2f" : inputDateFocusState ? "#1976d2" : "gray"
  const dateBorderBottomWidth = inputDateFocusState ? "2px" : "1px"
  const inputDateBaseStyle: React.CSSProperties = { padding: "5px", width: "100%", backgroundColor: "#00000000", border: "0px", outline: "none", fontSize: "large", fontFamily: "Arial", borderBottom: `solid ${dateBorderBottomColor} ${dateBorderBottomWidth}`, }
  const dateInputPropsFn = (): React.InputHTMLAttributes<HTMLInputElement> => {
    return {
      style: inputDateBaseStyle,
      value: formStateObj.date,
      onFocus: () => setInputDateFocusState(true),
      onBlur: () => setInputDateFocusState(false),
      onChange: (e: any) => onChangeInput("date", e.target.value),
    }
  }
  // ボタン
  const btnContentStyle: React.CSSProperties = { display: "flex", justifyContent: "space-between", padding: "10px", backgroundColor: "white", }
  const labelStyle: React.CSSProperties = { width: "60px", backgroundColor: props.item.labelColor, }
  const btnBlockStyle: React.CSSProperties = { display: "flex", gap: "10px", }
  const mainBtnStyle: React.CSSProperties = { borderRadius: "10px", backgroundColor: disableApplyBtnState ? DISABLE_COLOR : ACCENT_COLOR, color: "white", fontWeight: "bold", }
  const cancelBtnStyle: React.CSSProperties = { backgroundColor: "white", color: ACCENT_COLOR, boxShadow: "0px 0px 0px rgba(0,0,0,0.2)", }

  return (
    <div className='card' style={{ ...cardStyle }}>
      <div className='content' style={{ ...contentEditStyle }}>

        <div className='title' style={{ ...rowEditStyle, ...titleEditStyle }}>
          <TextField {...TextFieldPropsFn("title")} />
        </div>

        <div className='value' style={{ ...rowEditStyle, ...valueEditStyle }}>
          <div className='count' style={{ ...rowEditStyle, }}>
            <div>いま</div>
            <TextField {...TextFieldPropsFn("count")} />
            <div>回で</div>
            <TextField {...TextFieldPropsFn("countLimit")} />
            <div>回はヤバい</div>
          </div>
        </div>

        <div className='value' style={{ ...rowEditStyle, ...valueEditStyle }}>
          <div className='days' style={{ ...rowEditStyle, }}>
            <input type="date" {...dateInputPropsFn()} />
            <div>から</div>
            <TextField {...TextFieldPropsFn("dayCountLimit")} />
            <div>日はヤバい</div>
          </div>

        </div>
      </div>
      <div className='btnsRow' style={{ ...btnContentStyle }}>
        <div className='label' style={{ ...labelStyle }} onClick={() => setIsColorEditState((current) => !current)}></div>
        <div className='btnBlock' style={{ ...btnBlockStyle, gap: "0px" }}>
          <Button variant="contained" onClick={clickCancel} style={{ ...mainBtnStyle, ...cancelBtnStyle }}>キャンセル</Button>
          <Button variant="contained" onClick={clickApply} disabled={disableApplyBtnState} style={{ ...mainBtnStyle }}>ＯＫ</Button>
        </div>
      </div>
      {isColorEditState && <ColorPicker item={props.item} setItemState={props.setItemState} setIsColorEditState={setIsColorEditState} />}
    </div>
  );
}

export default ItemEdit;
