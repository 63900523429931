import { v4 as uuidv4 } from 'uuid';
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { DataStore } from 'aws-amplify';
import awsconfig from "./aws-exports";
import { Item } from "./models";
import * as type from './type';
import { MOCK, userIdMOCK } from "./Const";
import { getCurrentDate } from "./commonLogics";



Amplify.configure(awsconfig);

/**
 * Item新規作成関数
 * @param formObj 
 */
export const createItem = async (userId: string, formObj: type.itemEditForm) => {
  try {
    const createdItem = await DataStore.save(
      new Item({
        userId: userId,
        id: uuidv4(),
        title: formObj.title,
        count: Number(formObj.count),
        countLimit: Number(formObj.countLimit),
        date: formObj.date,
        dayCountLimit: Number(formObj.dayCountLimit),
        labelColor: formObj.labelColor,
      })
    )
    console.log('Post saved successfully!', createdItem)
    return createdItem
  } catch (error) {
    console.log('Error saving post', error)
  }
}

/**
 * EditFormの内容を適用する関数
 * @param id 
 * @param formObj 
 */
export const updateItemByEditForm = async (id: string, formObj: type.itemEditForm) => {
  const original = await DataStore.query(Item, (c) => c.id.eq(id));

  if (original) {
    const updatedItem = await DataStore.save(
      Item.copyOf(original[0], updated => {
        updated.title = formObj.title
        updated.count = Number(formObj.count)
        updated.countLimit = Number(formObj.countLimit)
        updated.date = formObj.date
        updated.dayCountLimit = Number(formObj.dayCountLimit)
      })
    )
    return updatedItem
  }
}

/**
 * 「つかった」ボタンでカウントアップを保存する関数
 * @param id 
 * @param newCount 
 */
export const updateItemCount = async (id: string, newCount: number) => {
  const original = await DataStore.query(Item, (c) => c.id.eq(id));

  if (original) {
    const updatedItem = await DataStore.save(
      Item.copyOf(original[0], updated => {
        updated.count = Number(newCount)
      })
    )
    return updatedItem
  }
}

export const updateItemLabelColor = async (id: string, labelColor: string) => {
  const original = await DataStore.query(Item, (c) => c.id.eq(id));

  if (original) {
    const updatedItem = await DataStore.save(
      Item.copyOf(original[0], updated => {
        updated.labelColor = labelColor
      })
    )
    return updatedItem
  }
}

/**
 * ItemのCountとdateをリセットする関数
 * @param id 
 */
export const resetItem = async (id: string) => {
  const original = await DataStore.query(Item, (c) => c.id.eq(id));

  if (original) {
    const updatedItem = await DataStore.save(
      Item.copyOf(original[0], updated => {
        updated.count = 0
        updated.date = getCurrentDate()
      })
    )
    return updatedItem
  }
}

/**
 * Itemを削除する関数
 * @param id 
*/
export const deleteItem = async (id: string) => {
  const toDelete = await DataStore.query(Item, (c) => c.id.eq(id));
  console.dir(toDelete)
  if (toDelete) {
    const deletedItem = await DataStore.delete(toDelete[0]);
    return deletedItem
  }
}

/**
 * Itemを削除する関数
 * @param userId 
*/
export const deleteAllItem = async (userId: string) => {
  const toDelete = await DataStore.query(Item, (c) => c.userId.eq(userId));
  if (toDelete) {
    await DataStore.delete(toDelete);
  }
}

/**
 * Itemをローカルから削除する関数
 * @param userId 
*/
export const clearItem = async () => {
  await DataStore.clear();
}

/**
 * ユーザーの全アイテムを取得する
 * @param userId 
 * @returns 
 */
export const getItemsByUserId = async (userId: string) => {
  const items = await DataStore.query(Item, (c) => c.userId.eq(userId));
  return items
}

/**
 * MOCKをDBに保存する関数
 */
export const createItemMOCK = async () => {
  MOCK.map(async (item) => {
    await createItem(userIdMOCK, item)
  })
}

/**
 * ItemのMOCKを削除する関数
*/
export const deleteAllItemMOCK = async () => {
  const toDelete = await DataStore.query(Item, (c) => c.userId.eq(userIdMOCK));
  if (toDelete) {
    await DataStore.delete(toDelete);
  }
}