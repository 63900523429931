import { v4 as uuidv4 } from 'uuid';
import * as type from './type';


export const MAIN_COLOR = "#455A64"
export const SUB_COLOR = "#CDCDCD"
export const ACCENT_COLOR = "#3FB3E7"
export const DISABLE_COLOR = "gray"

export const userIdMOCK = "f2d3b3ee-45a3-4d53-a73f-5d24b5a5a5a5"

export const MOCK: type.itemEditForm[] = [{
  title: "スポンジ",
  count: "2",
  countLimit: "5",
  date: "2023-05-20",
  dayCountLimit: "14",
  labelColor: "black",
}, {
  title: "タイトル",
  count: "1",
  countLimit: "8",
  date: "2023-05-25",
  dayCountLimit: "7",
  labelColor: "gray",
}]
