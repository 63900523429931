import React, { useEffect, useState } from 'react'
import { CirclePicker, ColorResult, SliderPicker, SwatchesPicker } from 'react-color';
import * as type from './type';
import { updateItemLabelColor } from './dbLogics';
import './App.css';

const ColorPicker = (props: type.ColorPickerChildProps) => {
  /**
   * カラー変更を適用する関数
   * @param color 
   * @param e 
   */
  const onColorChange = async (color: ColorResult, e: React.ChangeEvent<HTMLInputElement>) => {
    // setLabelColorState(color.hex)
    const updatedItem = await updateItemLabelColor(props.item.id, color.hex)
    // 更新がうまくいけば更新内容でstateを変える
    if (updatedItem) {
      props.setItemState(updatedItem)
    }

    props.setIsColorEditState(false)
  }

  /**
   * カラーピッカーの外をクリックしたときにカラーピッカーを閉じる関数
   */
  const onClickOutFrame = () => {
    props.setIsColorEditState(false)
  }


  // style
  // カラーピッカー部分
  const colorPickerDivStyle: React.CSSProperties = { padding: "10px", backgroundColor: "white" }
  const colorPickerMaskStyle: React.CSSProperties = { position: 'fixed', top: "0px", left: "0px", zIndex: "10000", width: "100vw", height: "100vh", }
  const colorPickerStyle: React.CSSProperties = { position: "relative", zIndex: "10001", }


  return (
    <div className='colorPickerDiv' style={{ ...colorPickerDivStyle }}>
      <div className='colorPickerMask' style={{ ...colorPickerMaskStyle }} onClick={onClickOutFrame}></div>
      <div className='colorPicker' style={{ ...colorPickerStyle }}>
        <SwatchesPicker color={props.item.labelColor} onChange={onColorChange} />
      </div>
    </div>
  )
}

export default ColorPicker;
